/* General Table Styling */
.tabulator {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Header Styling */
.tabulator .tabulator-header {
  background-color: #f4f4f4;
  color: #333;
  font-weight: bold;
  font-size: 10px;
  border-bottom: 3px solid orangered;
  
}




.tabulator .tabulator-header .tabulator-col {
  padding: 6px;
  border-right: 1px solid #ddd;

}

.tabulator .tabulator-header .tabulator-col:last-child {
  border-right: none;
}

.tabulator .tabulator-header .tabulator-sortable .tabulator-col {
  cursor: pointer;
}

/* Row Styling */
.tabulator .tabulator-row {
  background-color: #fff;
  border-bottom: 1px solid #eee;
}

.tabulator .tabulator-row:nth-child(even) {
  background-color: #f9f9f9;
}

/* Cell Styling */
.tabulator .tabulator-cell {
  padding: 1px;
  border-right: 1px solid #ddd;
  transition: background-color 0.3s ease;
}

.tabulator .tabulator-cell:last-child {
  border-right: none;
}

/* Hover and Selected Row Styling */
.tabulator .tabulator-row:hover {
  background-color: #f1f1f1;
}

.tabulator .tabulator-row.tabulator-selected {
  background-color: #e0f7fa;
  color: #00796b;
}

/* Pagination Styling */
.tabulator .tabulator-footer {
  padding: 5px;
  background-color: orangered;
  border-top: 1px solid #f7eded;
}

.tabulator .tabulator-pagination {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabulator .tabulator-pagination button {
  background-color: #00796b;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tabulator .tabulator-pagination button:hover {
  background-color: #004d40;
}

.tabulator .tabulator-pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Custom Scrollbar */
.tabulator .tabulator-tableHolder::-webkit-scrollbar {
  width: 8px;
}

.tabulator .tabulator-tableHolder::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.tabulator .tabulator-tableHolder::-webkit-scrollbar-thumb {
  background-color: #00796b;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

/* Responsive Table Styling */
.tabulator .tabulator-responsive-collapse {
  background-color: #e0f7fa;
}

.tabulator .tabulator-responsive-collapse .tabulator-responsive-collapse-toggle {
  background-color: #00796b;
  color: #fff;
  border: none;
  padding: 15px;
  cursor: pointer;
}

.tabulator .tabulator-responsive-collapse .tabulator-responsive-collapse-toggle:hover {
  background-color: #004d40;
}

.custom-time-editor .tabulator-editor input {
  font-size: 12px; /* Adjust the font size */
  padding: 2px;    /* Adjust the padding */
}

.tabulator .tabulator-cell.editing {
  padding: 2px; /* Adjusted padding to be more visible if needed */
  border-right: 1px solid #ddd; /* You can modify or remove this if it creates unwanted lines */
  background-color: #e0f7fa; /* Optional: Change background color to indicate editing */
}