/* Ensure the month dropdown is displayed correctly */
.flatpickr-monthDropdown-months {
    display: inline-block; /* Make sure the month dropdown is visible */
    width: auto;
    padding: 0 10px; /* Add padding to give the month some space */
    text-align: center;
}

.flatpickr-monthDropdown-months select {
    display: inline-block; /* Ensure the dropdown is inline */
    font-size: 1.2rem;     /* Increase font size */
    padding: 5px;          /* Add padding for better clickability */
    background: transparent; /* Keep background transparent to avoid conflicts */
    border: none;
    color: inherit;        /* Use the inherited text color */
    text-align: center;
    appearance: none;      /* Remove default dropdown styling (optional) */
}

/* Optional: Style the dropdown arrow to make it clearer */
.flatpickr-monthDropdown-months::after {
    content: "▼";          /* Add a custom dropdown arrow */
    padding-left: 5px;
    font-size: 0.8rem;
}

/* Ensure the year and month container has enough space */
.flatpickr-current-month {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-size: 1.2rem;
    padding: 10px;
    box-sizing: border-box;
    position: relative;  /* Ensure dropdown and text are positioned correctly */
}
